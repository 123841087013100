import React from "react";
import ChatBot from "react-chatbotify";
import HeaderLogo from "./HeaderLogo";

const MyChatBot = () => {
  const [form, setForm] = React.useState({});
  const [availableOptions, setAvailableOptions] = React.useState([
    "Is iWant Hub free?",
    "Who can use iWant Hub?",
    "What features are included?",
    "Is iWant Hub secure?",
    "Can I delete my account?",
  ]);
  const [showOptions, setShowOptions] = React.useState(true);

  const formStyle = {
    marginTop: 10,
    marginLeft: 20,
    border: "1px solid #491d8d",
    padding: 10,
    maxWidth: 300,
  };

  function handleUserInput(path, userInput, nextPath, nextMessage) {
    setForm((prevForm) => ({
      ...prevForm,
      [path]: userInput,
    }));
    return {
      path: nextPath,
      message: nextMessage,
    };
  }

  const flow = {
    start: {
      message: `Hi 👋, this is our virtual assistant!
        Tell us how we can help.`,
      options: availableOptions,
      path: "process_options",
    },

    unknown_input: {
      message: `Thank you for reaching out! Our sales team will contact you within 24 hours. For immediate assistance, please email us admin@lathran.com.`,

      options: availableOptions,
      transition: { duration: 0 },
    },

    process_options: {
      transition: { duration: 0 },
      chatDisabled: true,
      path: async (params) => {
        let responseMessage = "";
        switch (params.userInput) {
          case "Is iWant Hub free?":
            responseMessage = "Yes, all features are 100% free. ";
            break;
          case "Who can use iWant Hub?":
            responseMessage =
              "Any business looking for simple, free document management. ";
            break;
          case "What features are included?":
            responseMessage =
              "Secure document storage, uploads from PC or link, and basic organization. ";
            break;
          case "Is iWant Hub secure?":
            responseMessage = "Yes, all documents are encrypted for security.";
            break;
          case "Can I delete my account?":
            responseMessage =
              "Yes, contact support or use the settings to delete your account. ";
            break;
          default:
            return "unknown_input";
        }

        // Inject the response message
        await params.injectMessage(responseMessage);

        // Make sure options are shown again after processing the selected option
        setAvailableOptions((prevOptions) =>
          prevOptions.filter((option) => option !== params.userInput)
        );

        setShowOptions(true);
        return "repeat";
      },
    },
    repeat: {
      message: "Do you need any other help?",

      options: availableOptions,
      path: "process_options",
    },
  };

  return (
    <ChatBot
      settings={{
        general: { embedded: true },
        // chatHistory: { storageKey: "example_faq_bot" },
      }}
      flow={flow}
      showOptions={showOptions}
    />
  );
};

export default MyChatBot;
