import { createChatBotMessage } from "react-chatbot-kit";
import Suggestions from "./Suggestions";
import BotLogo from "./BotLogo";
import HeaderLogo from "./HeaderLogo";
import CustomSendButton from "./CustomSendButton";

const botName = "Bot";

const config = {
  botName: "Bot",
  customComponents: {
    header: () => <HeaderLogo />,
    botAvatar: (props) => <BotLogo {...props} />,
    chatButton: (props) => <CustomSendButton {...props} />,
  },

  initialMessages: [
    createChatBotMessage(
      `Hi 👋, this is our virtual assistant!
Tell us how we can help.`,
      {
        widget: "suggestions",
      }
    ),
  ],
  botName: botName,
  customStyles: {
    botMessageBox: {
      // backgroundColor: "#376B7E",
      borderRadius: "24px 24px 0px 24px",
    },
    chatButton: {
      backgroundColor: "#5ccc9d",
    },
  },
  widgets: [
    {
      widgetName: "suggestions",
      widgetFunc: (props) => <Suggestions {...props} />,
    },
    // {
    //   widgetName: "botLogo",
    //   widgetFunc: (props) => <BotLogo {...props} />,
    // },
  ],
};

export default config;
