import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import video from "../Images/testingvideo.mp4";
import Cross from "../Images/cross.svg";
import play from "../Images/Play.svg";

function WatchModal(props) {
  const playVideo = () => {
    const video = document.getElementById("myVideo");

    video.play();

    video.style.filter = "none";

    document.querySelector(".play-btn").style.display = "none";
  };
  // transform: "translate(-50%, 50%)",

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="backdrop"
      >
        <Modal.Body>
          <div className="video-container">
            <video
              id="myVideo"
              style={{
                width: "100%",
                borderRadius: "20px",
                filter: "blur(5px)",
              }}
              controls
            >
              <source className="img-fluid" src={video} type="video/mp4" />
            </video>
          </div>

          <div
            className="play-btn"
            style={{
              position: "absolute",
              bottom: "50%",
              left: "46%",
              zIndex: "10",
              cursor: "pointer",
            }}
            onClick={playVideo}
          >
            <img className="img-fluid" src={play} alt="Play" />
          </div>
        </Modal.Body>
        <div className="text-center" style={{ marginBottom: "-3.5rem" }}>
          <img
            className="img-fluid"
            style={{ cursor: "pointer" }}
            onClick={props.onHide}
            src={Cross}
            alt=""
          />
        </div>
      </Modal>
    </div>
  );
}

export default WatchModal;
