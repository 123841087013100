import React from "react";

const BotLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#132B3D" />
      <path
        d="M14.6155 17.0008C14.6155 17.7659 13.9958 18.3855 13.2308 18.3855C12.4658 18.3855 11.8462 17.7659 11.8462 17.0008C11.8462 16.2358 12.4658 15.6162 13.2308 15.6162C13.9958 15.6162 14.6155 16.2358 14.6155 17.0008Z"
        fill="white"
      />
      <path
        d="M20.154 17.0008C20.154 17.7659 19.5344 18.3855 18.7694 18.3855C18.0044 18.3855 17.3848 17.7659 17.3848 17.0008C17.3848 16.2358 18.0044 15.6162 18.7694 15.6162C19.5344 15.6162 20.154 16.2358 20.154 17.0008Z"
        fill="white"
      />
      <path
        d="M24.308 14.9413V14.7406C24.3054 13.8717 23.9592 13.04 23.3448 12.4257C22.7304 11.8112 21.8987 11.4651 21.0299 11.4625H16.8656V10.8532C17.4359 10.4724 17.6903 9.76281 17.4904 9.10598C17.2913 8.44915 16.6864 8 16.0002 8C15.3139 8 14.709 8.44913 14.5099 9.10598C14.31 9.76281 14.5644 10.4725 15.1347 10.8532V11.4625H10.9704C10.1015 11.4651 9.2699 11.8112 8.65552 12.4257C8.04109 13.0401 7.69493 13.8717 7.69232 14.7406V14.9413C7.28905 15.0235 7 15.3784 7 15.7894V17.8664C7 18.0957 7.09087 18.3164 7.25356 18.4782C7.41538 18.6409 7.63607 18.7318 7.86539 18.7318C7.89395 18.7335 7.92164 18.7335 7.9502 18.7318C8.40454 20.466 9.41964 22.0004 10.838 23.0968C12.2564 24.1924 13.9985 24.7878 15.7906 24.7895H16.2094C18.0017 24.7878 19.7437 24.1924 21.162 23.0968C22.5804 22.0003 23.5954 20.466 24.0498 18.7318C24.0784 18.7335 24.1061 18.7335 24.1346 18.7318C24.364 18.7318 24.5846 18.6409 24.7464 18.4782C24.9091 18.3164 25 18.0957 25 17.8664V15.7894C25 15.3783 24.711 15.0235 24.3077 14.9413H24.308ZM22.5772 16.6912C22.5755 18.3796 21.9039 19.9979 20.7105 21.192C19.5163 22.3854 17.898 23.057 16.2097 23.0587H15.7908C14.1024 23.0569 12.4841 22.3854 11.29 21.192C10.0966 19.9978 9.42502 18.3795 9.4233 16.6912V14.7405C9.42935 13.8881 10.1182 13.1993 10.9706 13.1932H21.03C21.8824 13.1993 22.5713 13.8881 22.5773 14.7405L22.5772 16.6912Z"
        fill="white"
      />
      <path
        d="M17.3399 19.4241H14.6156C14.5109 19.4224 14.4105 19.4674 14.3439 19.5479C14.2764 19.6284 14.2487 19.7348 14.2695 19.8378C14.4503 20.6097 15.1383 21.1549 15.931 21.1549H16.0297C16.8181 21.1497 17.5 20.6054 17.6809 19.8378C17.7008 19.7357 17.6748 19.6301 17.6082 19.5505C17.5424 19.47 17.4438 19.4233 17.3399 19.4241Z"
        fill="white"
      />
    </svg>
  );
};

export default BotLogo;
