import React, { useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../Images/Logo.png";
import { NavLink } from "react-router-dom";
import { Link, animateScroll as scrollAnimate } from "react-scroll";
import { useLocation } from "react-router-dom";
import menue from "../Images/menue.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Header() {
  const location = useLocation();
  const Navigate = useNavigate();
  const navbarRef = useRef(null);
  // console.log(location.pathname);
  const [isContactPage, setIsContactPage] = useState(false);
  // const isContactPage = location.pathname === "/contact-us";

  useEffect(() => {
    setIsContactPage(location.pathname === "/");
  }, [location.pathname]);
  // useEffect(() => {

  //   setIsContactPage(
  //     ["/Contact-us", "/help-center", "/release-notes"].includes(location.pathname)
  //   );
  // }, [location.pathname]);

  // console.log(location.pathname);

  // const history = useHistory();

  const handleClick = () => {
    if (location.pathname !== "/") {
      Navigate("/");
      setTimeout(() => {
        scrollAnimate.scrollTo(
          document.getElementById("feature-section").offsetTop,
          {
            smooth: true,
            duration: 100,
            offset: -70,
          }
        );
      }, 500);
    } else {
      scrollAnimate.scrollTo(
        document.getElementById("feature-section").offsetTop,
        {
          smooth: true,
          duration: 100,
          offset: -70,
        }
      );
    }
  };
  const handleFAQS = () => {
    if (location.pathname !== "/help-center") {
      Navigate("/help-center");
      setTimeout(() => {
        scrollAnimate.scrollTo(document.getElementById("faqs").offsetTop, {
          smooth: true,
          duration: 100,
          offset: -70,
        });
      }, 500);
    } else {
      scrollAnimate.scrollTo(document.getElementById("faqs").offsetTop, {
        smooth: true,
        duration: 100,
        offset: -70,
      });
    }
  };

  const [expanded, setExpanded] = useState(false);
  const [toggledropdow, setToggledropdown] = useState(false);

  const handleDropdown = () => {
    setToggledropdown(!toggledropdow);
  };

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const closeNav = () => {
    setExpanded(false);
  };
  const [show, setShow] = useState(false);

  const handleMouseEnter = () => setShow(true);
  const handleMouseLeave = () => setShow(false);

  return (
    <div
      className={`Feather-font  ${
        isContactPage ? "header-bg" : "header-white"
      }`}
    >
      <div className="custom-container">
        <Navbar
          expand="lg"
          className={`Feather-font  py-4 ${
            isContactPage ? "header-bg" : " header-white"
          }`}
          expanded={expanded}
          onToggle={handleToggle}
        >
          <Navbar.Brand href="#">
            <NavLink to="/">
              <img className="logo-width" src={Logo} alt="" />
            </NavLink>
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={handleToggle}
          >
            <img src={menue} alt="" />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className=" w-100 d-flex justify-content-center header-right"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Link
                className="  header-text mx-2 nav-link Feather-font-bold"
                // to="/#feature-section"
                // onClick={handleClick}

                to="feature-section"
                smooth={true}
                duration={100}
                offset={-70}
                activeClassName="active "
                onClick={() => {
                  handleClick();
                  closeNav();
                }}
              >
                Feature
              </Link>
              {/* <NavLink
                className="  header-text mx-2 nav-link Feather-font-bold"
                to="/help-center"
                activeClassName="active-link"
                onClick={() =>
                  document
                    .getElementById("navbarScroll")
                    .classList.remove("show")
                }
              >
                Help Center
              </NavLink> */}

              <NavDropdown
                className="dropdown-hover mx-2  Feather-font-bold"
                onClick={handleDropdown}
                toggledropdow={toggledropdow}
                title={
                  <span
                    className={`${
                      show ? "text-green" : ""
                    } Feather-font-bold fw-bold`}
                  >
                    Help Center{" "}
                    {show ? (
                      <svg
                        className="ms-1"
                        width="14"
                        height="8"
                        viewBox="0 0 14 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L7 7L13 1"
                          stroke="#58cc02"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="ms-1"
                        width="14"
                        height="8"
                        viewBox="0 0 14 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L7 7L13 1"
                          stroke="#1E1E1E"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    )}
                  </span>
                }
                id="basic-nav-dropdown"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                show={toggledropdow}
                onToggle={handleDropdown}
              >
                <NavDropdown.Item>
                  <NavLink
                    onClick={closeNav}
                    // className="  header-text mx-2 nav-link Feather-font-bold"
                    className={({ isActive, isPending }) =>
                      `${
                        isPending ? "pending" : isActive ? "active" : ""
                      } header-text mx-2 nav-link Feather-font-bold`
                    }
                    to="/help-center"
                    // activeClassName="active-link"
                  >
                    <span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M4 4.69434V18.6943C4 20.3512 5.34315 21.6943 7 21.6943H17C18.6569 21.6943 20 20.3512 20 18.6943V8.69434C20 7.03748 18.6569 5.69434 17 5.69434H5C4.44772 5.69434 4 5.24662 4 4.69434ZM7.25 11.6943C7.25 11.2801 7.58579 10.9443 8 10.9443H16C16.4142 10.9443 16.75 11.2801 16.75 11.6943C16.75 12.1085 16.4142 12.4443 16 12.4443H8C7.58579 12.4443 7.25 12.1085 7.25 11.6943ZM7.25 15.1943C7.25 14.7801 7.58579 14.4443 8 14.4443H13.5C13.9142 14.4443 14.25 14.7801 14.25 15.1943C14.25 15.6085 13.9142 15.9443 13.5 15.9443H8C7.58579 15.9443 7.25 15.6085 7.25 15.1943Z"
                          fill="#58CC02"
                        />
                        <path
                          opacity="0.5"
                          d="M18 4.00038V5.86504C17.6872 5.75449 17.3506 5.69434 17 5.69434H5C4.44772 5.69434 4 5.24662 4 4.69434V4.62329C4 4.09027 4.39193 3.63837 4.91959 3.56299L15.7172 2.02048C16.922 1.84835 18 2.78328 18 4.00038Z"
                          fill="#58CC02"
                        />
                      </svg>
                    </span>
                    <span className="ms-1 user">User Guides</span>{" "}
                  </NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item
                // href="#action/3.2"
                // to="/help-center"
                // activeClassName="active-link"
                // onClick={() =>
                //   document
                //     .getElementById("navbarScroll")
                //     .classList.remove("show")
                // }
                >
                  <Link
                    className="  header-text mx-2 nav-link Feather-font-bold"
                    // to="/#feature-section"
                    // onClick={handleClick}

                    // to="faqs"
                    smooth={true}
                    duration={100}
                    offset={-70}
                    activeClassName="active "
                    onClick={() => {
                      handleFAQS();
                      closeNav();
                    }}
                  >
                    <span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.5"
                          d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                          fill="#58CC02"
                        />
                        <path
                          d="M12 7.75C11.3787 7.75 10.875 8.25368 10.875 8.875C10.875 9.28921 10.5392 9.625 10.125 9.625C9.71079 9.625 9.375 9.28921 9.375 8.875C9.375 7.42525 10.5503 6.25 12 6.25C13.4497 6.25 14.625 7.42525 14.625 8.875C14.625 9.58584 14.3415 10.232 13.883 10.704C13.7907 10.7989 13.7027 10.8869 13.6187 10.9708C13.4029 11.1864 13.2138 11.3753 13.0479 11.5885C12.8289 11.8699 12.75 12.0768 12.75 12.25V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V12.25C11.25 11.5948 11.555 11.0644 11.8642 10.6672C12.0929 10.3733 12.3804 10.0863 12.6138 9.85346C12.6842 9.78321 12.7496 9.71789 12.807 9.65877C13.0046 9.45543 13.125 9.18004 13.125 8.875C13.125 8.25368 12.6213 7.75 12 7.75Z"
                          fill="#58CC02"
                        />
                        <path
                          d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
                          fill="#58CC02"
                        />
                      </svg>
                    </span>
                    <span className="ms-1 user"> FAQS</span>
                  </Link>
                </NavDropdown.Item>

                <NavDropdown.Item>
                  <NavLink
                    // className="  header-text mx-2 nav-link Feather-font-bold"
                    className={({ isActive, isPending }) =>
                      `${
                        isPending ? "pending" : isActive ? "active" : ""
                      } header-text mx-2 nav-link Feather-font-bold`
                    }
                    to="/release-notes"
                    onClick={closeNav}
                    // activeClassName="active-link"
                  >
                    <div>
                      <span>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.5"
                            d="M21 15.9983V9.99826C21 7.16983 21 5.75562 20.1213 4.87694C19.3529 4.10856 18.175 4.01211 16 4H8C5.82497 4.01211 4.64706 4.10856 3.87868 4.87694C3 5.75562 3 7.16983 3 9.99826V15.9983C3 18.8267 3 20.2409 3.87868 21.1196C4.75736 21.9983 6.17157 21.9983 9 21.9983H15C17.8284 21.9983 19.2426 21.9983 20.1213 21.1196C21 20.2409 21 18.8267 21 15.9983Z"
                            fill="#58CC02"
                          />
                          <path
                            d="M8 3.5C8 2.67157 8.67157 2 9.5 2H14.5C15.3284 2 16 2.67157 16 3.5V4.5C16 5.32843 15.3284 6 14.5 6H9.5C8.67157 6 8 5.32843 8 4.5V3.5Z"
                            fill="#58CC02"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M6.25 10.5C6.25 10.0858 6.58579 9.75 7 9.75H17C17.4142 9.75 17.75 10.0858 17.75 10.5C17.75 10.9142 17.4142 11.25 17 11.25H7C6.58579 11.25 6.25 10.9142 6.25 10.5ZM7.25 14C7.25 13.5858 7.58579 13.25 8 13.25H16C16.4142 13.25 16.75 13.5858 16.75 14C16.75 14.4142 16.4142 14.75 16 14.75H8C7.58579 14.75 7.25 14.4142 7.25 14ZM8.25 17.5C8.25 17.0858 8.58579 16.75 9 16.75H15C15.4142 16.75 15.75 17.0858 15.75 17.5C15.75 17.9142 15.4142 18.25 15 18.25H9C8.58579 18.25 8.25 17.9142 8.25 17.5Z"
                            fill="#58CC02"
                          />
                        </svg>
                      </span>
                      <span className="ms-1 user">Release Notes</span>
                    </div>
                  </NavLink>
                </NavDropdown.Item>
              </NavDropdown>

              {/* <NavDropdown title="Link" id="navbarScrollingDropdown">
                <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action4">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action5">
                  Something else here
                </NavDropdown.Item>
              </NavDropdown> */}
              {/* <Nav.Link to="/contactus" className=""> */}
              <NavLink
                // className="  header-text mx-2 nav-link Feather-font-bold"
                className={({ isActive, isPending }) =>
                  `${
                    isPending ? "pending" : isActive ? "active" : ""
                  } header-text mx-2 nav-link Feather-font-bold`
                }
                to="/Contact-us"
                // activeClassName="active-link"
                onClick={closeNav}
              >
                Book a Demo
              </NavLink>
              {/* </Nav.Link> */}
            </Nav>
            {/* <Form className="d-flex">
              <Form.Control
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
              /> */}
            <a href="https://dxbtechnologyhub.com/login#" target="_blank">
              <Button
                variant="outline-success"
                className="btn custom-login-btn  Feather-font-bold"
              >
                Login
              </Button>
            </a>
            {/* </Form> */}
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
}

export default Header;
