import React, { useState } from "react";
import { Nav, Tab, Row, Col } from "react-bootstrap";
import MyChatBot from "./MyChatBot";
import { useEffect } from "react";

function ReleaseNotes() {
  const [activeTab, setActiveTab] = useState("/home");

  const handleSelect = (selectedKey) => {
    setActiveTab(selectedKey);
  };

  const [chatVisible, setChatVisible] = useState(false);

  const toggleChatbot = () => {
    setChatVisible(!chatVisible);
  };
  useEffect(() => {
    const footer = document.querySelector(".footer");
    const releaseMargin = document.querySelector(".release-margin");

    if (!footer || !releaseMargin) {
      console.error("Footer or release margin element not found.");
      return;
    }

    const handleScroll = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          releaseMargin.style.position = "absolute";
          releaseMargin.style.bottom = "0";
        } else {
          releaseMargin.style.position = "fixed";
          releaseMargin.style.bottom = "auto";
        }
      });
    };

    const observer = new IntersectionObserver(handleScroll, {
      root: null,
      threshold: 0,
    });

    observer.observe(footer);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="custom-container releaase-notes-bottom-margin">
      <div className="chat-icon" onClick={toggleChatbot}>
        {chatVisible ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-x"
          >
            <path d="M18 6 6 18" />
            <path d="m6 6 12 12" />
          </svg>
        ) : (
          <svg
            width="32"
            height="32"
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M41.8332 13.7083C41.8332 6.22947 35.7704 0.166664 28.2915 0.166664C23.9522 0.166664 20.0896 2.21312 17.6113 5.39001C28.1813 5.79867 36.6248 14.4966 36.6248 25.1667C36.6248 25.3965 36.6209 25.6254 36.6131 25.8533L37.3054 26.0385C39.3186 26.5772 41.1603 24.7354 40.6217 22.7222L40.3563 21.7304C40.142 20.9293 40.2708 20.0829 40.6154 19.3286C41.3974 17.6166 41.8332 15.7134 41.8332 13.7083Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M33.4998 25.1667C33.4998 34.3714 26.0379 41.8333 16.8332 41.8333C14.2592 41.8333 11.8215 41.2498 9.64516 40.2079C8.89715 39.8498 8.0497 39.7241 7.24856 39.9384L4.69431 40.6218C2.68112 41.1605 0.839326 39.3187 1.37798 37.3055L2.06141 34.7513C2.27577 33.9501 2.15003 33.1027 1.79192 32.3547C0.749995 30.1783 0.166504 27.7406 0.166504 25.1667C0.166504 15.9619 7.62843 8.5 16.8332 8.5C26.0379 8.5 33.4998 15.9619 33.4998 25.1667ZM9.5415 27.25C10.6921 27.25 11.6248 26.3173 11.6248 25.1667C11.6248 24.0161 10.6921 23.0833 9.5415 23.0833C8.39091 23.0833 7.45817 24.0161 7.45817 25.1667C7.45817 26.3173 8.39091 27.25 9.5415 27.25ZM16.8332 27.25C17.9838 27.25 18.9165 26.3173 18.9165 25.1667C18.9165 24.0161 17.9838 23.0833 16.8332 23.0833C15.6826 23.0833 14.7498 24.0161 14.7498 25.1667C14.7498 26.3173 15.6826 27.25 16.8332 27.25ZM24.1248 27.25C25.2754 27.25 26.2082 26.3173 26.2082 25.1667C26.2082 24.0161 25.2754 23.0833 24.1248 23.0833C22.9742 23.0833 22.0415 24.0161 22.0415 25.1667C22.0415 26.3173 22.9742 27.25 24.1248 27.25Z"
              fill="white"
            />
          </svg>
        )}
      </div>
      <div>
        {chatVisible && (
          <div
            className="react-chatbot-kit-chat-container show-chat"
            style={{ marginTop: "-27px" }}
          >
            <MyChatBot />

            {/* <Chatbot
                config={config}
                messageParser={MessageParser}
                actionProvider={ActionProvider}
                // placeholderIcon=""
                runInitialMessagesWithHistory
                disableScrollToBottom
              /> */}
          </div>
        )}
      </div>
      <h3 className="Feather-font-bold text-center res-top feature-font-size">
        Latest Product Updates
      </h3>
      <Tab.Container
        defaultActiveKey="/home"
        activeKey={activeTab}
        onSelect={handleSelect}
      >
        <Row>
          <Col sm={4}>
            <Nav className="flex-column release-margin" id="release-nav">
              <Row>
                {/* <Col sm={1}></Col> */}
                <Col sm={12}>
                  <Nav.Item className="top">
                    <Nav.Link
                      className="Feather-font-bold text-center"
                      eventKey="/home"
                    >
                      Web App
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="Feather-font-bold text-center my-2"
                      eventKey="link-1"
                      disabled
                    >
                      <span> IOS</span>{" "}
                      <span className="coming-color">(Coming Soon)</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="Feather-font-bold text-center"
                      eventKey="link-2"
                    >
                      Android
                    </Nav.Link>
                  </Nav.Item>
                </Col>

                {/* <Col sm={6}></Col> */}
              </Row>
            </Nav>
          </Col>
          <Col sm={8}>
            <Tab.Content>
              <Tab.Pane eventKey="/home">
                <div className="font-size">
                  <h4 className="genenral-sec april-text Feather-font-bold">
                    April 2024 Release
                  </h4>
                  <p className="no-margin-bottom mt-4 fw-bold OpenSans font-eighteen">
                    Effortless Onboarding & Profile Management
                  </p>
                  <ul>
                    {" "}
                    <li>
                      <span className="fw-bold font-eighteen">
                        Quick Signup:
                      </span>
                      <span className="ps-1 font-eighteen">
                        Choose from Google, Facebook, or email to sign up in
                        seconds and get straight to managing your files.
                      </span>
                    </li>
                    <li>
                      <span className="fw-bold font-eighteen">
                        Profile Control:
                      </span>
                      <span className="ps-1 font-eighteen">
                        Update your profile settings, and invite colleagues or
                        friends to join directly from your web dashboard.
                      </span>
                    </li>
                  </ul>
                  <p className="no-margin-bottom mt-4 fw-bold OpenSans font-eighteen">
                    Advanced File Search & Organization
                  </p>
                  <ul>
                    {" "}
                    <li>
                      <span className="fw-bold font-eighteen">
                        Powerful Global Search:
                      </span>
                      <span className="ps-1 font-eighteen">
                        Use the search bar to find files instantly, no matter
                        how large your document library.
                      </span>
                    </li>
                    <li>
                      <span className="fw-bold font-eighteen">
                        Comprehensive Filters:
                      </span>
                      <span className="font-eighteen">
                        Filter files by type (documents, images, videos), date,
                        or size to find exactly what you need quickly.
                      </span>
                    </li>
                    <li>
                      <span className="fw-bold font-eighteen">
                        Sorting Made Simple:{" "}
                      </span>
                      <span className="font-eighteen">
                        Sort files by name, date, size, or type for a cleaner,
                        more organized workspace.
                      </span>
                    </li>
                  </ul>
                  <p className="no-margin-bottom mt-4 fw-bold OpenSans font-eighteen">
                    Flexible Viewing Options
                  </p>
                  <ul>
                    {" "}
                    <li>
                      <span className="fw-bold font-eighteen">
                        Grid or List View:
                      </span>
                      <span className="ps-1 font-eighteen">
                        Choose between grid or list views to see your files in
                        the format that suits you best.
                      </span>
                    </li>
                    <li>
                      <span className="fw-bold font-eighteen">
                        Quick File Preview:
                      </span>
                      <span className="font-eighteen">
                        Get a sneak peek of files without opening them in grid
                        view.
                      </span>
                    </li>
                  </ul>
                  <p className="no-margin-bottom mt-4 fw-bold OpenSans font-eighteen">
                    Batch Actions for Maximum Efficiency
                  </p>
                  <ul>
                    {" "}
                    <li>
                      <span className="fw-bold font-size">
                        Bulk Move, Share, and Delete:{" "}
                      </span>
                      <span className="ps-1">
                        Manage multiple files at once—move, delete, or share
                        them all with just a few clicks.
                      </span>
                    </li>
                    <li>
                      <span className="fw-bold">Batch Download:</span>
                      <span>
                        Download several files at once to streamline backups or
                        team collaboration.
                      </span>
                    </li>
                  </ul>
                  <p className="no-margin-bottom mt-4 fw-bold OpenSans font-eighteen">
                    Favorites and Quick Access
                  </p>
                  <ul>
                    {" "}
                    <li>
                      <span className="fw-bold font-eighteen">
                        Favorites Section:
                      </span>
                      <span className="ps-1 font-eighteen">
                        Pin important files and access them instantly from the
                        favorites tab for easy retrieval.
                      </span>
                    </li>
                  </ul>
                  <p className="no-margin-bottom mt-4 fw-bold OpenSans font-eighteen">
                    Archive and File Recovery
                  </p>
                  <ul>
                    {" "}
                    <li>
                      <span className="fw-bold font-eighteen">
                        Archive for Storage:
                      </span>
                      <span className="ps-1 font-eighteen">
                        Keep files you don’t need immediately stored in the
                        archive and retrieve them later when required.
                      </span>
                    </li>
                    <li>
                      <span className="fw-bold font-eighteen">
                        Recycle Bin:{" "}
                      </span>
                      <span className="font-eighteen">
                        Accidentally deleted something? Recover files from the
                        recycle bin in just a few clicks
                      </span>
                    </li>
                  </ul>
                  <p className="no-margin-bottom mt-4 fw-bold OpenSans font-eighteen">
                    Secure Sharing & Metadata
                  </p>
                  <ul>
                    {" "}
                    <li>
                      <span className="fw-bold font-eighteen">
                        File Sharing Made Easy:
                      </span>
                      <span className="ps-1 font-eighteen">
                        Share files instantly by generating secure links or
                        sending them directly.
                      </span>
                    </li>
                    <li>
                      <span className="fw-bold font-eighteen">
                        Detailed File Information:
                      </span>
                      <span className="font-eighteen">
                        View file metadata before sharing to ensure you’re
                        sending the correct version.
                      </span>
                    </li>
                  </ul>
                  <p className="no-margin-bottom mt-4 fw-bold OpenSans font-eighteen">
                    Personalization & Security
                  </p>
                  <ul>
                    {" "}
                    <li>
                      <span className="fw-bold font-eighteen">
                        Account Security:
                      </span>
                      <span className="ps-1 font-eighteen">
                        Update your password regularly and log out securely when
                        finished.
                      </span>
                    </li>
                  </ul>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="link-1">
                <div>
                  <h3>Option 2 Content</h3>
                  <p>This is the content for Option 2.</p>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="link-2">
                <div className="font-size">
                  <h4 className="genenral-sec april-text Feather-font-bold">
                    November 2024 Release
                  </h4>
                  <p className="no-margin-bottom mt-4 fw-bold OpenSans font-eighteen">
                    Fast and Simple Mobile Onboarding
                  </p>
                  <ul>
                    {" "}
                    <li>
                      <span className="fw-bold font-eighteen">
                        Quick Mobile Signup:
                      </span>
                      <span className="ps-1 font-eighteen">
                        Get started instantly on mobile with Google, Facebook,
                        or email—no complex setup required.
                      </span>
                    </li>
                    <li>
                      <span className="fw-bold font-eighteen">
                        Password Recovery On-the-Go:
                      </span>
                      <span className="ps-1 font-eighteen">
                        Forgot your password? Reset it effortlessly from your
                        mobile device.
                      </span>
                    </li>
                  </ul>
                  <p className="no-margin-bottom mt-4 fw-bold OpenSans font-eighteen">
                    Mobile File Organization
                  </p>
                  <ul>
                    {" "}
                    <li>
                      <span className="fw-bold font-eighteen">
                        Search Anytime, Anywhere:
                      </span>
                      <span className="ps-1 font-eighteen">
                        Use the mobile search feature to find files fast, no
                        matter where you are.
                      </span>
                    </li>
                    <li>
                      <span className="fw-bold font-eighteen">
                        Filter for Quick Results:
                      </span>
                      <span>
                        Narrow your search with mobile filters by file type,
                        date, or size.
                      </span>
                    </li>
                    <li>
                      <span className="fw-bold font-eighteen">
                        Sort Files:{" "}
                      </span>
                      <span className="font-eighteen">
                        Organize files by name, size, date, or type directly
                        from your phone.
                      </span>
                    </li>
                  </ul>
                  <p className="no-margin-bottom mt-4 fw-bold OpenSans font-eighteen">
                    File Viewing and Favorites
                  </p>
                  <ul>
                    {" "}
                    <li>
                      <span className="fw-bold font-eighteen">
                        Mobile Grid & List Views:
                      </span>
                      <span className="ps-1 font-eighteen">
                        View your files in grid or list format, just like on the
                        web, but optimized for mobile.
                      </span>
                    </li>
                    <li>
                      <span className="fw-bold font-eighteen">
                        Quick File Previews:{" "}
                      </span>
                      <span className="font-eighteen">
                        Get a quick look at files before opening them with the
                        mobile preview feature.
                      </span>
                    </li>
                    <li>
                      <span className="fw-bold font-eighteen">
                        Favorite Files:{" "}
                      </span>
                      <span className="font-eighteen">
                        Mark files as favorites on mobile for instant access
                        anytime you need them.
                      </span>
                    </li>
                  </ul>
                  <p className="no-margin-bottom mt-4 fw-bold OpenSans font-eighteen">
                    Mobile Bulk Actions
                  </p>
                  <ul>
                    {" "}
                    <li>
                      <span className="fw-bold font-eighteen">
                        Batch File Management:
                      </span>
                      <span className="ps-1 font-eighteen">
                        Move, delete, or share multiple files at once with easy
                        mobile controls.
                      </span>
                    </li>
                    <li>
                      <span className="fw-bold font-eighteen">
                        Batch Downloads:{" "}
                      </span>
                      <span>
                        Download multiple files on the go to ensure you have
                        everything you need, even offline.
                      </span>
                    </li>
                  </ul>
                  <p className="no-margin-bottom mt-4 fw-bold OpenSans font-eighteen">
                    File Sharing & Collaboration on Mobile
                  </p>
                  <ul>
                    {" "}
                    <li>
                      <span className="fw-bold font-eighteen">
                        Share on the Go:
                      </span>
                      <span className="ps-1 font-eighteen">
                        Generate shareable links or send files directly from
                        your mobile device, making collaboration effortless.
                      </span>
                    </li>
                    <li>
                      <span className="fw-bold font-eighteen">
                        View File Details:
                      </span>
                      <span className="ps-1 font-eighteen">
                        Check important metadata before sharing to ensure
                        accuracy.
                      </span>
                    </li>
                  </ul>
                  <p className="no-margin-bottom mt-4 fw-bold OpenSans font-eighteen">
                    Mobile Archive & Recovery
                  </p>
                  <ul>
                    {" "}
                    <li>
                      <span className="fw-bold font-eighteen">
                        Archive for Long-Term Storage:
                      </span>
                      <span className="ps-1 font-eighteen">
                        Archive files directly from your mobile device and
                        unarchive them when you need them back.
                      </span>
                    </li>
                    <li>
                      <span className="fw-bold font-eighteen">
                        Recover Deleted Files:{" "}
                      </span>
                      <span className="font-eighteen">
                        Access your recycle bin on mobile and restore files with
                        just a tap.
                      </span>
                    </li>
                  </ul>
                  <p className="no-margin-bottom mt-4 fw-bold OpenSans font-eighteen">
                    Mobile Personalization & Security
                  </p>
                  <ul>
                    {" "}
                    <li>
                      <span className="fw-bold font-eighteen">
                        Light & Dark Mode:
                      </span>
                      <span className="ps-1 font-eighteen">
                        Switch between light and dark modes on mobile, making
                        file management easy on your eyes.
                      </span>
                    </li>
                    <li>
                      <span className="fw-bold font-eighteen">
                        Secure Password Changes:
                      </span>
                      <span className="font-eighteen">
                        Update your password and access security settings from
                        your mobile device to ensure peace of mind
                      </span>
                    </li>
                  </ul>
                  <p className="no-margin-bottom mt-4 fw-bold OpenSans font-eighteen">
                    Mobile Account Management
                  </p>
                  <ul>
                    {" "}
                    <li>
                      <span className="fw-bold font-eighteen">
                        Version Control:
                      </span>
                      <span className="ps-1 font-eighteen">
                        Keep your mobile app updated with the latest features
                        and improvements by checking your app version.
                      </span>
                    </li>
                    <li>
                      <span className="fw-bold font-eighteen">
                        Account Deactivation & Deletion:
                      </span>
                      <span className="ps-1 font-eighteen">
                        Manage your account from mobile—temporarily deactivate
                        it or delete it permanently when needed.
                      </span>
                    </li>
                  </ul>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}

export default ReleaseNotes;
