import React from "react";

const CustomSendButton = () => {
  console.log("checking");
  return (
    <button type="submit" className=".react-chatbot-kit-chat-btn-send">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1173_21443)">
          <path
            d="M10.9023 16.2644C10.9308 16.3354 10.9804 16.396 11.0443 16.4381C11.1082 16.4801 11.1835 16.5016 11.26 16.4997C11.3365 16.4977 11.4105 16.4724 11.4722 16.4271C11.5339 16.3819 11.5803 16.3188 11.6051 16.2464L16.4801 1.99642C16.5041 1.92997 16.5087 1.85805 16.4933 1.78909C16.4779 1.72012 16.4432 1.65697 16.3933 1.607C16.3433 1.55704 16.2801 1.52234 16.2112 1.50696C16.1422 1.49159 16.0703 1.49617 16.0038 1.52017L1.75384 6.39517C1.68146 6.41999 1.61841 6.46636 1.57315 6.52805C1.52788 6.58974 1.50257 6.6638 1.50061 6.74029C1.49865 6.81678 1.52014 6.89204 1.56219 6.95597C1.60423 7.01989 1.66483 7.06943 1.73584 7.09792L7.68334 9.48292C7.87136 9.5582 8.04218 9.67077 8.18552 9.81384C8.32885 9.95692 8.44173 10.1275 8.51734 10.3154L10.9023 16.2644Z"
            stroke="#FF9600"
            stroke-width="1.42857"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.3905 1.61035L8.18555 9.8146"
            stroke="#FF9600"
            stroke-width="1.42857"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1173_21443">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </button>
  );
};

export default CustomSendButton;
