// // import React from "react";

// // const Suggestions = ({ actions }) => {
// //   return (
// //     <div className="suggestions-container">
// //       <button
// //         className="suggestion-button"
// //         onClick={() => actions.handleFree()}
// //       >
// //         Is iWant Hub free?
// //       </button>
// //       <button className="suggestion-button" onClick={() => actions.handleHub()}>
// //         Who can use iWant Hub?
// //       </button>
// //       <button
// //         className="suggestion-button"
// //         onClick={() => actions.handleIncluded()}
// //       >
// //         What features are included?
// //       </button>
// //       <button
// //         className="suggestion-button"
// //         onClick={() => actions.handleCollaboration()}
// //       >
// //         Does iWant Hub support collaboration?
// //       </button>
// //       <button
// //         className="suggestion-button"
// //         onClick={() => actions.handleSecure()}
// //       >
// //         Is iWant Hub secure?
// //       </button>
// //       <button
// //         className="suggestion-button"
// //         onClick={() => actions.handleAccount()}
// //       >
// //         Can I delete my account?
// //       </button>
// //       <button
// //         className="suggestion-button"
// //         onClick={() => actions.handlePassword()}
// //       >
// //         How do I reset my password?
// //       </button>
// //       <button
// //         className="suggestion-button"
// //         onClick={() => actions.handleIssues()}
// //       >
// //         What should I do if I experience issues?
// //       </button>
// //     </div>
// //   );
// // };

// // export default Suggestions;

// // import React, { useState } from "react";

// // const Suggestions = ({ actions }) => {
// //   // Messages ko state mein store karain
// //   const [messages, setMessages] = useState([
// //     { id: 1, text: "Is iWant Hub free?", action: actions.handleFree },
// //     { id: 2, text: "Who can use iWant Hub?", action: actions.handleHub },
// //     {
// //       id: 3,
// //       text: "What features are included?",
// //       action: actions.handleIncluded,
// //     },
// //     {
// //       id: 4,
// //       text: "Does iWant Hub support collaboration?",
// //       action: actions.handleCollaboration,
// //     },
// //     { id: 5, text: "Is iWant Hub secure?", action: actions.handleSecure },
// //     { id: 6, text: "Can I delete my account?", action: actions.handleAccount },
// //     {
// //       id: 7,
// //       text: "How do I reset my password?",
// //       action: actions.handlePassword,
// //     },
// //     {
// //       id: 8,
// //       text: "What should I do if I experience issues?",
// //       action: actions.handleIssues,
// //     },
// //   ]);

// //   const handleRemoveMessage = (id, action) => {
// //     action();
// //     setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== id));
// //   };

// //   return (
// //     <div className="suggestions-container">
// //       {messages.map((message) => (
// //         <button
// //           key={message.id}
// //           className="suggestion-button"
// //           onClick={() => handleRemoveMessage(message.id, message.action)}
// //         >
// //           {message.text}
// //         </button>
// //       ))}
// //     </div>
// //   );
// // };

// // export default Suggestions;

// // import React, { useState } from "react";

// // const Suggestions = ({ actions }) => {
// //   // State to store the clicked message IDs
// //   const [clickedMessages, setClickedMessages] = useState([]);

// //   // Messages data with respective action handlers
// //   const [messages, setMessages] = useState([
// //     { id: 1, text: "Is iWant Hub free?", action: actions.handleFree },
// //     { id: 2, text: "Who can use iWant Hub?", action: actions.handleHub },
// //     {
// //       id: 3,
// //       text: "What features are included?",
// //       action: actions.handleIncluded,
// //     },
// //     {
// //       id: 4,
// //       text: "Does iWant Hub support collaboration?",
// //       action: actions.handleCollaboration,
// //     },
// //     { id: 5, text: "Is iWant Hub secure?", action: actions.handleSecure },
// //     { id: 6, text: "Can I delete my account?", action: actions.handleAccount },
// //     {
// //       id: 7,
// //       text: "How do I reset my password?",
// //       action: actions.handlePassword,
// //     },
// //     {
// //       id: 8,
// //       text: "What should I do if I experience issues?",
// //       action: actions.handleIssues,
// //     },
// //   ]);

// //   const handleRemoveMessage = (id, action) => {
// //     action();
// //     // Add the clicked message ID to the state to hide it temporarily
// //     setClickedMessages((prev) => [...prev, id]);
// //   };

// //   return (
// //     <div className="suggestions-container">
// //       {messages
// //         .filter((message) => !clickedMessages.includes(message.id))
// //         .map((message) => (
// //           <button
// //             key={message.id}
// //             className="suggestion-button"
// //             onClick={() => handleRemoveMessage(message.id, message.action)}
// //           >
// //             {message.text}
// //           </button>
// //         ))}
// //     </div>
// //   );
// // };

// // export default Suggestions;

// import React, { useState } from "react";

// const Suggestions = ({ actions }) => {
//   // State to store the clicked message IDs
//   const [clickedMessages, setClickedMessages] = useState([]);

//   // Messages data with respective action handlers
//   const messages = [
//     { id: 1, text: "Is iWant Hub free?", action: actions.handleFree },
//     { id: 2, text: "Who can use iWant Hub?", action: actions.handleHub },
//     {
//       id: 3,
//       text: "What features are included?",
//       action: actions.handleIncluded,
//     },
//     {
//       id: 4,
//       text: "Does iWant Hub support collaboration?",
//       action: actions.handleCollaboration,
//     },
//     { id: 5, text: "Is iWant Hub secure?", action: actions.handleSecure },
//     { id: 6, text: "Can I delete my account?", action: actions.handleAccount },
//     {
//       id: 7,
//       text: "How do I reset my password?",
//       action: actions.handlePassword,
//     },
//     {
//       id: 8,
//       text: "What should I do if I experience issues?",
//       action: actions.handleIssues,
//     },
//   ];

//   const handleButtonClick = (message) => {
//     message.action();
//     setClickedMessages((prev) => [...prev, message.id]);
//   };

//   return (
//     <div className="suggestions-container">
//       {messages.map((message) =>
//         clickedMessages.includes(message.id) ? (
//           <div key={message.id} className="clicked-message">
//             {message.text} option has been selected.
//           </div>
//         ) : (
//           <button
//             key={message.id}
//             className="suggestion-button"
//             onClick={() => handleButtonClick(message)}
//           >
//             {message.text}
//           </button>
//         )
//       )}
//     </div>
//   );
// };

// export default Suggestions;

// import React, { useState } from "react";

// const Suggestions = ({ actions }) => {
//   // State to store the clicked message IDs
//   const [clickedMessages, setClickedMessages] = useState([]);
//   const [currentMessage, setCurrentMessage] = useState(null);

//   // Messages data with respective action handlers
//   const messages = [
//     { id: 1, text: "Is iWant Hub free?", action: actions.handleFree },
//     { id: 2, text: "Who can use iWant Hub?", action: actions.handleHub },
//     {
//       id: 3,
//       text: "What features are included?",
//       action: actions.handleIncluded,
//     },
//     {
//       id: 4,
//       text: "Does iWant Hub support collaboration?",
//       action: actions.handleCollaboration,
//     },
//     { id: 5, text: "Is iWant Hub secure?", action: actions.handleSecure },
//     { id: 6, text: "Can I delete my account?", action: actions.handleAccount },
//     {
//       id: 7,
//       text: "How do I reset my password?",
//       action: actions.handlePassword,
//     },
//     {
//       id: 8,
//       text: "What should I do if I experience issues?",
//       action: actions.handleIssues,
//     },
//   ];

//   const handleButtonClick = (message) => {
//     message.action();
//     setCurrentMessage(message.id); // Set the current message as clicked
//     setTimeout(() => {
//       // After the action completes (simulating with timeout)
//       setClickedMessages((prev) => [...prev, message.id]);
//       setCurrentMessage(null); // Allow the user to click the next button
//     }, 1000); // Simulate response time (adjust as needed)
//   };

//   return (
//     <div className="suggestions-container">
//       {clickedMessages.length === messages.length ? (
//         <div className="all-messages-done">
//           All messages have been clicked and processed.
//         </div>
//       ) : (
//         messages.map((message) =>
//           clickedMessages.includes(message.id) ? (
//             <div key={message.id} className="clicked-message">
//               {message.text} option has been selected.
//             </div>
//           ) : (
//             <button
//               key={message.id}
//               className="suggestion-button"
//               onClick={() => handleButtonClick(message)}
//               disabled={currentMessage !== null}
//             >
//               {message.text}
//             </button>
//           )
//         )
//       )}
//     </div>
//   );
// };

// export default Suggestions;

// import React, { useState } from "react";

// const Suggestions = ({ actions }) => {
//   const [clickedMessages, setClickedMessages] = useState([]);
//   const [currentMessage, setCurrentMessage] = useState(null);

//   const messages = [
//     { id: 1, text: "Is iWant Hub free?", action: actions.handleFree },
//     { id: 2, text: "Who can use iWant Hub?", action: actions.handleHub },
//     {
//       id: 3,
//       text: "What features are included?",
//       action: actions.handleIncluded,
//     },
//     {
//       id: 4,
//       text: "Does iWant Hub support collaboration?",
//       action: actions.handleCollaboration,
//     },
//     { id: 5, text: "Is iWant Hub secure?", action: actions.handleSecure },
//     { id: 6, text: "Can I delete my account?", action: actions.handleAccount },
//     {
//       id: 7,
//       text: "How do I reset my password?",
//       action: actions.handlePassword,
//     },
//     {
//       id: 8,
//       text: "What should I do if I experience issues?",
//       action: actions.handleIssues,
//     },
//   ];

//   const handleButtonClick = (message) => {
//     message.action();
//     setCurrentMessage(message.id);

//     setClickedMessages((prev) => [...prev, message.id]);

//     // setTimeout(() => {
//     //   setCurrentMessage(null);
//     // }, 2000);
//   };

//   return (
//     <div className="suggestions-container">
//       {clickedMessages.length === messages.length ? (
//         <div className="all-messages-done">
//           All suggestions have been answered.
//         </div>
//       ) : currentMessage !== null ? (
//         <div className="clicked-message">
//           {messages.find((msg) => msg.id === currentMessage).text} has been
//           selected.
//         </div>
//       ) : (
//         messages
//           .filter((msg) => !clickedMessages.includes(msg.id))
//           .map((message) => (
//             <button
//               key={message.id}
//               className="suggestion-button"
//               onClick={() => handleButtonClick(message)}
//             >
//               {message.text}
//             </button>
//           ))
//       )}
//     </div>
//   );
// };

// export default Suggestions;

import React, { useState } from "react";

const Suggestions = (props) => {
  console.log("Props at suggestions", props);

  const { actions, state } = props;

  const [clickedMessages, setClickedMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState(null);

  const messages = [
    { id: 1, text: "Is iWant Hub free?", action: actions.handleFree },
    { id: 2, text: "Who can use iWant Hub?", action: actions.handleHub },
    {
      id: 3,
      text: "What features are included?",
      action: actions.handleIncluded,
    },
    // {
    //   id: 4,
    //   text: "Does iWant Hub support collaboration?",
    //   action: actions.handleCollaboration,
    // },
    { id: 5, text: "Is iWant Hub secure?", action: actions.handleSecure },
    { id: 6, text: "Can I delete my account?", action: actions.handleAccount },
    // {
    //   id: 7,
    //   text: "How do I reset my password?",
    //   action: actions.handlePassword,
    // },
    // {
    //   id: 8,
    //   text: "What should I do if I experience issues?",
    //   action: actions.handleIssues,
    // },
  ];

  const handleButtonClick = (message) => {
    // setCurrentMessage(message);

    setClickedMessages((prev) => [...prev, message.id]);

    // setTimeout(() => {
    //   setCurrentMessage(null);
    // }, 2000);
    message.action();
  };

  console.log("Clciked Messages", clickedMessages);

  return (
    <div className="suggestions-container">
      {clickedMessages.length === messages.length ? (
        <div className="all-messages-done">
          {/* All suggestions have been answered. */}
        </div>
      ) : currentMessage !== null ? (
        <div className="clicked-message">{currentMessage.text}</div>
      ) : (
        messages
          .filter((msg) => {
            return !state?.messages.some((item) => {
              return item.question_id == msg.id;
            });
          })
          .map((message) => (
            <button
              key={message.id}
              className="suggestion-button"
              onClick={() => handleButtonClick(message)}
            >
              {message.text}
            </button>
          ))
      )}
    </div>
  );
};

export default Suggestions;
